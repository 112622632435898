import React from 'react'
import './aboutme.css'
import Experience from '../../components/experience/Experience'
import Education from '../../components/education/Education'
import { Cursos } from '../../components'
import {Tags} from '../../components'

const Aboutme = () => {
    return (
        <div className='portoflio__aboutme'>
            <div className='portfolio__aboutme_intro'>
            <h1> <b>Fabián Ortega</b> <span alt="Emoji de hombre usando un notebook" role="img" aria-label="notebook-user">👨‍💻</span></h1>
            <br></br>
            <p>Soy un Científico de Datos  con  especialización en Inteligencia de Negocios, mi experiencia abarca áreas  como Machine Learning, Deep Learning, análisis de redes y Procesamiento de Lenguaje Natural (BERT, LLMs). 
                He desarrollado  competencias en el manejo de grandes volúmenes de datos, en el diseño e implementación de procesos de ETL, también en visualización datos a través de herramientas como Power BI y Shiny Apps.
                Mi habilidad técnica se extiende a lenguajes de programación como R, Python, STATA y JavaScript. También estoy familiarizado con stacks como Google Cloud Platform, AWS y plataformas de máquinas virtuales como Paperspace. <br></br>
                Tengo interés en políticas públicas, I+D+i, evaluación de impacto y las tecnologías en general. 👨‍💻</p>
            </div>
            <Tags tags={['Python', 'R', 'SQL', 'Google Cloud Platform', 'NLP', 'Tensorflow', 'Pytorch', 'Scikit-learn']}/>
            <div>
            <Experience/>

            {/*<Education/>*/}
            
            <Cursos/>
            </div>
        </div>
    )
}

export default Aboutme

import React from 'react'
import './fraude.css'
import notebook3  from '../../../assets/proyectos/proyecto-3/credit-card-fraud-analysis.json'
import JupyterViewer  from "react-jupyter-notebook";
import { github } from 'react-syntax-highlighter/dist/esm/styles/hljs';


const Proyecto3 = () => {
 
    return (
        <div className='portafolio__proyectos_main' >
            <div className='portafolio__proyectos_code'>
            <JupyterViewer rawIpynb={notebook3}
            mediaAlign={'center'}
            displayOutput={'scroll'}
            displaySource={'show'}
            codeBlockStyles={github}
            />
            </div>
        </div>
    )
}

export default Proyecto3
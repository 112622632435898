import React from 'react';
import JupyterViewer  from "react-jupyter-notebook";
import './02series.css'
import proyecto_viz2  from '../../../assets/viz/proyecto-2/02Series.json'
import PDFfile1 from '../../../assets/viz/proyecto-2/asesinos-serie.pdf'
import { Document, pdfjs, Page } from 'react-pdf/dist/esm/entry.webpack'
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;



// Asesinos en serie

const Viz2 = () => {

    return(

        <div className="viz_proyecto__intro">
            <JupyterViewer
            rawIpynb={proyecto_viz2}
            />
        <div className="viz_proyecto__text">
            <h1>Resultado Final</h1>
            <p>Luego de realizar los gráficos con python y matplotlib, le dimos los retoques finales usando Adobe ilustrator.  Para el gráfico de línea que imita sangre nos basamos en  una visualización
                "Iraqs Bloody tool" de Simon Scarr. Editamos el fondo del gráfico para similar un papel arrugado y darle textura. Los datos fueron obtenidos de  una investigación sobre asesinos en serie de
                Florida Gulf Coast University, además de datos e imagenes de Wikipedia.</p>
            <Document file={PDFfile1} 
            loading="Cargando documento..." >
        <Page pageNumber={1} 
        scale={1}
        width={1344}
        />
      </Document>
        </div>
        </div>
    )

}
export default Viz2
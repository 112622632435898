import React, { Component } from 'react' ;
import {
    BrowserRouter as Router,
    Routes,
    Route
  } from "react-router-dom";
import { Navbar,  Footer, ParticlesBackground } from './components';
import {  Home,Aboutme, Proyectos,Proyecto1, Proyecto2, Proyecto3,Viz} from './containers'
import Viz1 from './containers/viz/01-mapas/01mapas';
import Viz2 from './containers/viz/02-series/02series';
import Viz3 from './containers/viz/03-d3js/03d3js';

import './App.css';



class App extends Component  {
  render() {
    return (
      
        <Router>
        <div className='App'>
          <ParticlesBackground/>
        <Navbar/>
        <div className="portfolio__body" >
          <Routes >
            <Route path='/' element={<Home/>}/>
          <Route path='/about-me' element={<Aboutme/>} />
          <Route path='projects/*' element={<Proyectos/>} />
                    <Route path="/projects/proyecto-1" element={<Proyecto1 />} />
                    <Route path="/projects/proyecto-2" element={<Proyecto2 />} />
                    <Route path="/projects/proyecto-3" element={<Proyecto3 />} />
                    <Route path="/projects/proyecto-4" element={<Proyecto1 />} />
          <Route path='/data-viz/*' element={<Viz/>} />
                       <Route path="/data-viz/viz-1" element={<Viz1 />} />
                        <Route path="/data-viz/viz-2" element={<Viz2 />} />
                        <Route path="/data-viz/viz-3" element={<Viz3 />} />
          </Routes>
          </div>
          <Footer />
          </div>
      </Router>

      
    )
}
};

export default App

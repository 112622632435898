import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';

import Typewriter from 'typewriter-effect';
import {
  Link
} from "react-router-dom";
import './navbar.css';

const Navbar = () => {
    const [toggleMenu, setToggleMenu] = useState(false);
  
    return (
      <div className="portfolio__navbar" >
        <div className="portfolio__navbar-links">
          <Link to="/">
          <div className="portfolio__navbar_brand_typewriter" onClick={() => setToggleMenu(false)} >
          <Typewriter
            options={{
              strings: ['Fabián  Ortega' , "Data Scientist", "Data Analyst"],
              delay: 90,
              pauseFor: 2000,
              deleteSpeed: 90,
              autoStart: true,
              loop: true
            }}
          />
          </div>
          </Link>
          <div className="portfolio__navbar-links_container">
            <p><Link to="/about-me">Acerca de mí</Link></p>
            <p><Link to="/projects">Proyectos</Link></p>
            <p><Link to="/data-viz">Data Viz</Link></p>
          </div>
        </div>
        
        <div className="portfolio__navbar-menu">
          {toggleMenu
            ? <RiCloseLine color="var(--color-title)" size={27} onClick={() => setToggleMenu(false)} />
            : <RiMenu3Line color="var(--color-title)"  size={27} onClick={() => setToggleMenu(true)} />}
          {toggleMenu && (
          <div className="portfolio__navbar-menu_container scale-up-center">
            <div className="portfolio__navbar-menu_container-links">
              <p><Link to="/about-me" onClick={() => setToggleMenu(false)} >Acerca de mí</Link></p>
              <p><Link to="/projects" onClick={() => setToggleMenu(false)} >Proyectos</Link></p>
              <p><Link to="/data-viz" onClick={() => setToggleMenu(false)} >Data Viz</Link></p>
            </div>
          </div>
          )}
        </div>
      </div>
    );
  };
  
  export default Navbar;

import React from 'react'
import './proyectos.css'
import Card from '../../components/card/Card';
import proyecto1  from '../../assets/proyectos/proyecto-1.png'
import proyecto2 from '../../assets/proyectos/proyecto-2-binnario.png'
import proyecto3 from '../../assets/proyectos/proyecto-3/cover-img.jpeg'



const Proyectos = () => {
   
    
    return (
        <div className='portafolio__proyectos' >
        <div className='portafolio__proyectos__intro'>
        <h1>Proyectos de datos</h1>
        <p>
            En esta sección iré subiendo algunos proyectos de ciencia de datos. El objetivo es poner en práctica  habilidades e ir aprendiendo más a medida que voy realizando cosas.  <span alt='Emoji de computador' role="img" aria-label="notebook-user">👨‍💻</span>
        </p>
        </div>
        <Card title="Procesamiento de Lenguaje Natural: Clasificación de texto con Transformers" 
        tags = {['NLP', 'DeepLearning', 'Pytorch', 'Transformers']}
        path='proyecto-1'
        texto="Clasificación de texto utilizando una red neuronal  Transformers con un modelo BERT en español  (BETO) en Pytorch y la API de Hugginface 🤗."
        imagen={proyecto1} 
        alt="Logo de Hugginface y Pytorch"></Card>

    <Card
        title="Riesgo de fraude en tarjetas de crédito"
        texto = "Desafío de Kaggle en donde se busca predecir clasificar transacciones de tarjetas de crédito para identificar potenciales fraudes."
        tags = { ['Machine Learning', 'Credit Risk', 'XGBoost', 'SMOTE']}
        path = 'proyecto-3'
        imagen={proyecto3}
        alt='pendiente'
        
        />

        <Card
        title="Desafío Binnario.ai e ITAU"
        texto = "Desafío de ciencia de datos que busca predecir el tipo de sistema operativo (Windows, Macintosh o Linux) que utiliza el usuario en la web de ITAU en base a duración de la visita, número de acciones del usuario, cantidad de páginas visitadas, etc."
        tags = { ['Machine Learning', 'PCA', 'RandomForest', 'XGB']}
        path = 'proyecto-2'
        imagen={proyecto2}
        alt='pendiente'
        
        />

        </div>

    )
}

export default Proyectos

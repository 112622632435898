import React from 'react'
import './Tags.css'

const Tags = (props) => {
    
   const tags = props.tags

  return (
    <div className='portafolio_tags'>
           {tags.map(tag => <span className='portafolio_tags__tag' key={tag}> {tag} </span>)} 
       </div>
  )

}

export default Tags
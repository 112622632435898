import React from 'react';
import './footer.css';
import { BsTwitter, BsLinkedin, BsGithub } from "react-icons/bs";
import { Link } from 'react-router-dom';

const Footer = () => (
  <div className="portafolio__footer section__padding">

    <div className="portafolio__footer-links">
      <div className="portafolio__footer-links_div">
      <Link to='/'>
      <p>Home</p>
      </Link>
      <Link to='/about-me'>
      <p>Acerca de mí</p>
      </Link>
      <Link to='/projects/'>
      <p>Proyectos</p>
      </Link>
      <Link to='/data-viz/'>
      <p>Data Viz</p>
      </Link>
        
      </div>
      <div className="portafolio__footer-links_social">
        <a href="https://github.com/fabianigual" target="_blank" rel="noopener noreferrer">
            < BsGithub className="portafolio_footer__social_logo" />
            </a>
              <a href="https://www.linkedin.com/in/fabián-ortega-vega-0a68a6230/" target="_blank" rel="noopener noreferrer">
              <BsLinkedin className="portafolio_footer__social_logo"/>
            </a>
            <a href="https://github.com/fabianigual" target="_blank" rel="noopener noreferrer">
              <BsTwitter className="portafolio_footer__social_logo"/>
            </a>
      </div>
    </div>

    <div className="portafolio__footer-copyright">
      <p>2022 @Fabianigual. Todos los derechos reservados.</p>
    </div>
  </div>
);

export default Footer;
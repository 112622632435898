import React from 'react';
import './cursos.css'
import { FaGreaterThan } from 'react-icons/fa';


function Cursos() {
    const cursos = [
        { title:"Taller de Inteligencia de negocios", 
            subtitle : "Pontificia Universidad Católica de Chile",
            type : "Curso",
            date :"Junio de 2020",
            key: 1

        },
    
            { title:"Evaluación de Impacto de Programas Sociales", 
            subtitle :"J-PAL",
            type :"Curso Online",
            date :"Febrero-marzo 2020",
            key: 2
        },

            { title:"R para Data Science - Intermedio", 
            subtitle :"EY MetricArts",
            type :"Curso",
            date :"Octubre - noviembre 2019",
            key: 3
        },

            { title:"Introducción a la programación con Python", 
            subtitle :"Desafío Latam",
            type :"Curso",
            date :"Agosto 2019",
            key: 4
        },
        ]; 
    
    const cursosTitulos  = cursos.map(cursos =>  
                                                        <div key={cursos.key}  className='lista_titulos'>
                                                            
                                                        <div className='lista_row'>
                                                        <FaGreaterThan/>
                                                        <div className='lista_head'>
                                                        
                                                        <p className='lista_titulos_titulo'>{cursos.title}</p>
                                                        <p className='lista_cursos_subtitle'>{cursos.subtitle}</p>
                                                        <p className="lista_cursos_type">{cursos.type}</p>
                                                        </div>  
                                                        </div>
                                                        <div className='lista_date'>
                                                            <p>{cursos.date}</p>
                                                            </div>
                                                        </div>
                                                            ) 

  return (
  <div className='portfolio__aboutme_cursos'>
    <h2>Cursos y capacitaciones</h2>
    {cursosTitulos}
  </div>
  )
};

export default Cursos;

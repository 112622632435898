import React from 'react'
import './desafio.css'
import notebook2  from '../../../assets/proyectos/proyecto-2/notebook2.json'
import JupyterViewer  from "react-jupyter-notebook";
import { github } from 'react-syntax-highlighter/dist/esm/styles/hljs';


const Proyecto2 = () => {
 
    return (
        <div className='portafolio__proyectos_main' >
            <div className='portafolio__proyectos_code'>
            <JupyterViewer rawIpynb={notebook2}
            mediaAlign={'center'}
            displayOutput={'scroll'}
            displaySource={'show'}
            codeBlockStyles={github}
            />
            </div>
        </div>
    )
}

export default Proyecto2
import React from 'react'
import './Card.css'
import { Link } from 'react-router-dom'
import {Tags} from '../../components'

const Card = (props) => {

  const scrollToTop = () => {
    window.scrollTo(0, 0)
}
 
  const title = props.title
  const texto = props.texto
  const imagen = props.imagen
  const path = props.path
  const tags = props.tags
  const alt = props.alt
  const btn_text = "Ver proyecto >"
    return (

  <div className="projects__card">	

	<div className="projects__card_left">
		
	<h1>{title}</h1>
  <div>
  <Tags tags={tags}/>
  </div>
		<p className="projects__card_left_texto">
      {texto}
    </p>

    <div className="card-btn" onClick={scrollToTop}>
    <Link to={path}>
          {btn_text}
      </Link>
  </div>

 
</div>
	
<div className="projects__card_img">
	<img src={imagen} alt={alt}></img>
</div>
</div>

    )

}

export default Card
import React from 'react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import "./experience.css"
import { MdWork, MdOutlineSchool } from "react-icons/md";
import uc from '../../assets/logo-uc.png'
import corfo from '../../assets/logo-corfo.svg'
import udd from  '../../assets/logo-udd.png'
import udp from  '../../assets/logo-udp.png'

const Experience = () => {
  return (
      <div className='portfolio__aboutme_experience'>
        <h1 className='portfolio__aboutme_experience_title'>Educación y Experiencia Laboral</h1>
          <VerticalTimeline  className='"portfolio__aboutme_timeline' 
          lineColor={ '#9b9b9b' }
          >
  
  <VerticalTimelineElement
   position={ 'right' }
    className="vertical-timeline-element--work"
    contentStyle={{  color: '#000' }}
    date="Mayo 2019 - presente"
    iconStyle={{ background: '#EA638C', color: '#fff' }}
    icon={<MdWork />}
  >
    <h3 className="vertical-timeline-element-title">Data Scientist y analista de datos</h3>
    <h4 className="vertical-timeline-element-subtitle">Unidad de Inteligencia de Negocios, Gerencia de Innovación de CORFO</h4>
    <p className='vertical-timeline-element-text'>
      Data Scientist  y Analista de BI. Parte del equipo creador de <a href='https://www.datainnovacion.cl'  target="_blank" rel="noopener noreferrer">www.datainnovacion.cl</a>,  primera plataforma de datos abiertos de innovación de Chile. 
      Diseño e implementación del ETL que alimenta plataforma, además del diseño  de visualizaciones. Desarrollo de modelos de procesamiento de lenguaje natural y Deep learning para clasificación de texto abierto 
      (formularios de postulación). Desarrollo e implementación de modelo BERT para clasificación de proyectos según objetivos de Programa Modelo de desarrollo productivo sostenible del Ministerio de Economía. Experiencia en evaluación de proyectos de Machine Learning y TIC's. 
    </p>
    <img className='titulos__logos' alt='Logo de CORFO' src={corfo}></img>
  </VerticalTimelineElement>

  <VerticalTimelineElement
  position={ 'right' }
    className="vertical-timeline-element--education"
    date="Abril 2021 - Diciembre 2022"
    iconStyle={{ background: '#5E4AE3', color: '#fff' }}
    icon={<MdOutlineSchool />}
  >
    <h3 className="vertical-timeline-element-title">Magíster en Data Science</h3>
    <h4 className="vertical-timeline-element-subtitle">Universidad del Desarrollo</h4>
    <p className='vertical-timeline-element-text'>
      Cursos relevantes: Machine Learning avanzado, Deep Learning avanzado, Visión computacional, Procesamiento de lenguate Natural, Big Data y Cloud computing con GCP, ciencia de redes. 
    </p>
    <img className='titulos__logos_2' src={udd} alt='Logo de Universidad del Desarrollo' ></img>
  </VerticalTimelineElement>

  <VerticalTimelineElement
  position={ 'right' }
    className="vertical-timeline-element--education"
    date="Enero 2022 - Julio 2022"
    iconStyle={{ background: '#5E4AE3', color: '#fff' }}
    icon={<MdOutlineSchool />}
  >
    <h3 className="vertical-timeline-element-title">Diplomado en Big Data</h3>
    <h4 className="vertical-timeline-element-subtitle">Universidad del Desarrollo</h4>
    <img className='titulos__logos_2' src={udd} alt='Logo de Universidad del Desarrollo' ></img>
  </VerticalTimelineElement>

  <VerticalTimelineElement
  position={ 'right' }
    className="vertical-timeline-element--work"
    date="Mayo de 2020 - Enero de 2021"
    iconStyle={{ background: '#5E4AE3', color: '#fff' }}
    icon={<MdOutlineSchool />}
  >
    <h3 className="vertical-timeline-element-title">Diplomado en Data Science</h3>
    <h4 className="vertical-timeline-element-subtitle">Pontificia Universidad Católica de Chile</h4>
    <img className='titulos__logos' src={uc} alt='Logo de Universidad Católica de Chile' ></img>
  </VerticalTimelineElement>
  <VerticalTimelineElement
  position={ 'right' }
    className="vertical-timeline-element--education"
    date="Enero - Abril 2019"
    iconStyle={{ background: '#EA638C', color: '#fff' }}
    icon={<MdWork />}
  >
    <h3 className="vertical-timeline-element-title">Unidad de Estudios y Evaluaciones</h3>
    <h4 className="vertical-timeline-element-subtitle">Gerencia de Innovación, CORFO</h4>
    <p className='vertical-timeline-element-text'>
    Práctica Profesional
    </p>
    <img className='titulos__logos' src={corfo} alt='Logo de CORFO' ></img>
  </VerticalTimelineElement>
  <VerticalTimelineElement
  position={ 'right' }
    className="vertical-timeline-element--education"
    date="Diciembre 2019"
    iconStyle={{ background: '#5E4AE3', color: '#fff' }}
    icon={<MdOutlineSchool />}
  >
    <h3 className="vertical-timeline-element-title">Ingeniería Comercial con mención en Economía</h3>
    <h4 className="vertical-timeline-element-subtitle">Universidad Diego Portales</h4>
    <p className='vertical-timeline-element-text'>
      Cursos relevantes: Estadística, inferencia estadística, cálculo, algebra lineal,  econometría, microeconomía, macroeconomía, análisis computacional de la economía, optimización dinámica. 
      </p>
    <img className='titulos__logos_2' src={udp} alt='Logo de Universidad Diego Portales' ></img>
  </VerticalTimelineElement>
  
</VerticalTimeline>
      </div>
  )
};

export default Experience;

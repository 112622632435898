import React, { } from 'react';
import './viz.css';
import Card from '../../components/card/Card';
import viz1  from '../../assets/proyectos/proyecto-2.png';
import viz2  from '../../assets/viz/viz2.png';
//import viz3  from '../../assets/viz/viz1.png';
  
const Viz = () => {

    return (
        <div className='portafolio_data_viz'>
            <div className='portafolio_data_viz__intro'>
                <h1>Proyectos de visualización</h1>
            <p> 
            Aquí iré subiendo proyectos relacionados a visualización de datos que involucren distintas herramientas y su respectivo código,
            como por ejemplo ggplot en el caso de R, matplotlib o seaborn en python, Highchart.js o d3js en el caso de Javascript. 
            </p>
            </div>
            <div className='portafolio_data_viz__cards'>
            <Card
        title="Generador de mapas con Python"
        tags = {["Mapas", "Open Street Map", "Python"]}
        texto="Código que genera mapas de ciudades utilizando la API de google maps. Exporta los mapas en png o pdf."
        alt="mapa"
        imagen={viz1}
        path='viz-1'
        ></Card>

<Card
        title="Asesinos en pd.Series"
        tags = {["Visualización de datos", "Python", "Matplotlib"]}
        texto="Trabajo realizado para el curso Visualización de la información del MDS UDD. Obtuvimos una base de datos de asesinos seriales entre el 1900 y el 2020. "
        alt="mapa"
        imagen={viz2}
        path='viz-2'
        ></Card>

        {/* 
        <Card 
        title="Visualización de datos con D3.js"
        texto="Espacio práctico una serie de visualizaciones  dinámicas con DJANGO + d3.js"
        imagen={viz3} 
        alt="chord d3.js"
        path="viz-3"
        />
        */}
        </div>
        </div>
    )
}

export default Viz;

import React from 'react'

function Viz3() {
  return (
    <div>
        <h1>D3 JS</h1>
        
    </div>
  )
}

export default Viz3;
import React from 'react'
import './proyecto_nlp.css'
import notebook1  from '../../../assets/proyectos/proyecto-1/notebook1.json'
import JupyterViewer  from "react-jupyter-notebook";
import { github } from 'react-syntax-highlighter/dist/esm/styles/hljs';


const Proyecto1 = () => {
 
    return (
        <div className='portafolio__proyectos_main' >
            <div className='portafolio__proyectos_texto'>
            <h1>NLP - Clasificación de texto usando Transformers</h1>
            <h2>¿Qué son los transformers? </h2>
            <p>Tipo de red neuronal que aprende contexto mediante el seguimiento de relaciones en datos secuenciales basados en self-attention. Para esto utilicé Transformers de Hugginface + Pytorch para clasificar si los proyectos financiados por la gerencia de Innovación son "sostenibles" o no. 
                Los datos son obtenidos a través de la API de <a href="http://www.datainnovacion.cl">Data Innovación</a>.</p>
            </div>
            <div className='portafolio__proyectos_code'>
            <JupyterViewer rawIpynb={notebook1}
            mediaAlign={'center'}
            displayOutput={'show'}
            displaySource={'show'}
            codeBlockStyles={github}
            />
            </div>
        </div>
    )
}

export default Proyecto1

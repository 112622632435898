import React from 'react';
import './home.css'
import hola from '../../assets/hola.png'
import fabs from '../../assets/fabs.png'
//import Interest from '../../components/interest/Interest'


const Home = () => {

  return (
       <div className='portfolio__home' >
           <div className='portfolio__home_intro'>
               <img className='portfolio__home_fabs' src={fabs} alt="imagen"></img>
           <div className='portfolio__home_texto'>
           <p>Hola <img className='emoji-hola' src = {hola} alt="Saludo"/>, soy</p>
           <p className='portfolio__home_name'>Fabián Ortega. </p>
           <p className='portfolio__home_subtext'>
            Soy Data Scientist y analista de datos trabajando en el mundo de la Innovación e I+D.  Actualmente trabajo en la Unidad de Inteligencia de negocios de la Gerencia de Innovación de CORFO.
            Tengo formación en economía  y un magíster en Ciencia de datos, cuento con conocimientos en BI, en diversas áreas de machine learning y deep learning, además de habilidades 
            en visualización de datos. Programo principalmente en Python y R, además de un poco de JS. Empecé a programar por Mr. Robot  <span alt="emoji de robot" role="img" aria-label="robot">🤖</span>
           </p>
           </div>
           </div>
       </div>
  )
}; 

export default Home;

import React from 'react';
import JupyterViewer  from "react-jupyter-notebook";
import './01mapas.css';
import proyecto_viz1  from '../../../assets/viz/proyecto-1/mapas.json';


// Mapas en python
const Viz1 = () => {
  return(

      <div className="viz_proyecto__intro">
          <JupyterViewer
          rawIpynb={proyecto_viz1}
          />
      </div>
  )

}
export default Viz1